<template>
    <div class="user">
        <div v-if="loading" class="loading">Loading...</div>
        <div v-if="error" class="error">{{ error }}</div>
        <div v-if="post" class="content">
            <h2>{{ post.title }}</h2>
            <p>{{ post.body }}</p>
        </div>
        <h1>The post ID is {{ pid }}</h1>
        <div>Post ID: {{ $route.params.pid }}</div>
    </div>
</template>

<script>    
    export default {
        
        data() {
            return {
                loading: false,
                post: null,
                error: null,
                baseUrl: 'https://jsonplaceholder.typicode.com/posts/'
            }
        },
        
        // props: ["pid"],
        props: {
            pid: String
        },
        
        created() {
            this.fetchData()
        },
        
        methods: {
            fetchData() {
                this.error = this.post = null
                this.loading = true
                //console.log( 'fetchData', this.$route.params.pid, typeof this.$route.params.pid, this.pid, typeof this.pid )
                let url = new URL(this.$route.params.pid, this.baseUrl);
                console.log(url.href);
                fetch(url.href)
                    .then(response => response.json())
                    .then(data => {
                    this.loading = false;
                    //console.log(data);
                    this.post = data
                    //console.log('this.post', this.post.title, this.post.body);
                })
                
            }
        }
        
    };
</script>