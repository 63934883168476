import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/PageHome.vue";
import Tournaments from "@/views/PageTournaments.vue";
import Tournament from "@/views/PageTournament.vue";
import Teams from "@/views/PageTeams.vue";
import Team from "@/views/PageTeam.vue";
import Posts from "@/views/PagePosts.vue";
import Post from "@/views/PagePost.vue";
import PostNotFound from "@/views/PagePostNotFound.vue";
import Admin from "@/views/PageAdmin.vue";
import NotFound from "@/views/PageNotFound.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/tournaments",
        name: "Tournaments",
        component: Tournaments
    },
    {
        path: "/tournaments/:tid",
        name: "Tournament",
        component: Tournament,
        props: true
    },
    {
        path: "/teams",
        name: "Teams",
        component: Teams,
        props: true
    },
    {
        path: "/teams/:gid",
        name: "Team",
        component: Team,
        props: true
    },
    {
        path: "/posts",
        name: "Posts",
        component: Posts
    },
    {
        path: "/posts/:pid",
        name: "Post",
        component: Post,
        props: true
    },
    {
        path: "/posts/:pathMatch(.*)*",
        name: "PostNotFound",
        component: PostNotFound
    },
    {
        path: "/admin",
        name: "Admin",
        component: Admin
    },
    {
        //path: "/:notFound",
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: NotFound
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            // scroll to hash
            return {
                el: to.hash,
                top: -10,
                behavior: 'smooth'
            }
        } else {
            // scroll to top
            return {
                top: 0,
                behavior: 'smooth'
            }
        }
        
    },
});

export default router;