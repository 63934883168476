<template>
     <tr>
        <!--<td>{{ item.id }}</td>-->
        <td>
            <span v-if="item.status == 'upcoming'" v-bind:class="item.status">Upcoming</span>
            <span v-else-if="item.status == 'inprogress'" v-bind:class="item.status">In Progress</span>
            <span v-else-if="item.status == 'finished'" v-bind:class="item.status">Finished</span>
            <span v-else></span>
        </td>
        <td>
            <div v-if="item.date_start && (item.status == 'inprogress' || item.status == 'upcoming')"><span v-html="convertDate(item.date_start)"></span></div>
            <div>
                <button type="submit" @click.prevent="startGame" v-if="game_started == false"><small>Start game</small></button>
                <div v-if="game_started" v-html="convertDate(date_start)"></div>
                <button type="submit" @click.prevent="stopGame" v-if="game_started == true && (goals_team_red == 10 || goals_team_blue == 10)"><small>Stop game</small></button>
            </div>
         </td>
        <td class="team_red">
            <div><span class="logo" v-if="team_red_logo"><img v-bind:src="team_red_logo"></span><span class="name" v-if="item.team_red">{{ item.team_red[0].value }}</span><span class="winner" v-if="item.winner[0] && item.team_red[0] && item.winner[0].id == item.team_red[0].id">🏆</span></div>
            <div v-if="game_started == true">
                <button type="submit" @click.prevent="addGoalRedTeam"><small>Add goal</small></button>
                <button type="submit" @click.prevent="removeGoalRedTeam"><small>Remove goal</small></button>
            </div>
         </td>
        <td class="team_blue">
            <div><span class="logo" v-if="team_blue_logo"><img v-bind:src="team_blue_logo"></span><span class="name" v-if="item.team_blue">{{ item.team_blue[0].value }}</span><span class="winner" v-if="item.winner[0] && item.team_blue[0] && item.winner[0].id == item.team_blue[0].id">🏆</span></div>
            <div v-if="game_started == true">
                <button type="submit" @click.prevent="addGoalBlueTeam"><small>Add goal</small></button>
                <button type="submit" @click.prevent="removeGoalBlueTeam"><small>Remove goal</small></button>
            </div>
         </td>
        <td class="score">
            <span class="team_red" v-if="item.team_red">{{ goals_team_red }}</span>
            <span class="divider">—</span>
            <span class="team_red" v-if="item.team_blue">{{ goals_team_blue }}</span>
        </td>
    </tr>
</template>

<script>
    
    export default {
        name: 'GameRow',
        
        // Properties returned from data() become reactive state
        // and will be exposed on `this`.
        data() {
            return {
                loading: false,
                error: null,
                baseUrl: 'https://api.baserow.io',
                finished: null,
                date_kickoff: null,
                team_red_name: null,
                team_blue_name: null,              
                team_red_winner: false,
                team_blue_winner: false,
                goals_team_red: this.item.team_red_goals,
                goals_team_blue: this.item.team_blue_goals,        
                team_red_logo: false,
                team_blue_logo: false,
                date_start: null,
                date_end: null,
                game_started: false,
                status: null
            }
        },       
        
        props: {
            item: Object
        },
        
        created() {
            //console.log(this.item);
            this.checkStatus();
            this.getRedTeam(this.item.team_red);
            this.getBlueTeam(this.item.team_blue);
        },
        
        // Lifecycle hooks are called at different stages
        // of a component's lifecycle.
        // This function will be called when the component is mounted.
        mounted() {},
        
        // Methods are functions that mutate state and trigger updates.
        // They can be bound as event listeners in templates.
        methods: {
            checkStatus(){
                if(this.item.status == 'inprogress'){
                    this.game_started = true
                }
            },
            addGoalRedTeam(){
                if( this.goals_team_red < 10 ){
                   this.goals_team_red++;
                }
            },
            addGoalBlueTeam(){
                if( this.goals_team_blue < 10 ){
                   this.goals_team_blue++;
                }
            },
            removeGoalRedTeam(){
                if( this.goals_team_red > 0 ){
                   this.goals_team_red--;
                }
            },
            removeGoalBlueTeam(){
                if( this.goals_team_blue > 0 ){
                   this.goals_team_blue--;
                }
            },
            startGame(){
                this.game_started = true
                const now = new Date();
                this.date_start = now.toISOString()
            },
            stopGame(){
                this.game_started = true
                const now = new Date();
                this.date_end = now.toISOString()
                const response = confirm('Do you want to stop the game now?');
                if (response) {
                    alert("Ok was pressed");
                } else {
                    alert("Cancel was pressed");
                }
            },
            convertDate(date){
                if( date === null ){
                    return '';  
                } else {
                    const dateObject = new Date(date),
                          day = String(dateObject.getDate()).padStart(2, '0'),
                          month = String((dateObject.getMonth() + 1)).padStart(2, '0'),
                          year = dateObject.getFullYear(),
                          hour = String(dateObject.getHours()).padStart(2, '0'),
                          minutes = String(dateObject.getMinutes()).padStart(2, '0');
                    return `${day}/${month}/${year} - ${hour}:${minutes}`;                    
                }                
            },
            countGoals(goals){
                return goals.length
            },
            getRedTeam(team_array){
                if( !team_array && team_array.length == 0){
                    return false;
                }
                const team_id = team_array[0].id; 
                //console.log(team_id);
                
                this.error = null
                this.loading = true
                
                // baserow.io Teams table fields
                // table ID 162682
                // field_1073617 name
                // field_1073652 games_won
                // field_1073678 logo
                let url = new URL('/api/database/rows/table/162682/' + team_id +'/?user_field_names=true', this.baseUrl);
                //console.log('url.href', url.href);

                // Request() constructor
                const headers = new Headers({
                    Authorization: "Token ElvFpuln7uKKxkC7C34WnKY4Z5RdA1ND"
                });
                const request = new Request(url.href, {
                    method: "GET",
                    headers: headers,
                    mode: "cors",
                    cache: "default"
                });
                fetch(request)
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error("Something went wrong on API server!");
                        }
                    })
                    .then((data) => {
                        //console.log('fetchGoals', data.results.length);
                        this.loading = false;
                        console.log('data:', data.logo[0]);
                        this.team_red_logo = data.logo[0].thumbnails.tiny.url
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                
            },
            getBlueTeam(team_array){
                if( !team_array && team_array.length == 0){
                    return false;
                }
                const team_id = team_array[0].id; 
                //console.log(team_id);
                
                this.error = null
                this.loading = true
                
                // baserow.io Teams table fields
                // table ID 162682
                // field_1073617 name
                // field_1073652 games_won
                // field_1073678 logo
                let url = new URL('/api/database/rows/table/162682/' + team_id +'/?user_field_names=true', this.baseUrl);
                //console.log('url.href', url.href);

                // Request() constructor
                const headers = new Headers({
                    Authorization: "Token ElvFpuln7uKKxkC7C34WnKY4Z5RdA1ND"
                });
                const request = new Request(url.href, {
                    method: "GET",
                    headers: headers,
                    mode: "cors",
                    cache: "default"
                });
                fetch(request)
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error("Something went wrong on API server!");
                        }
                    })
                    .then((data) => {
                        //console.log('fetchGoals', data.results.length);
                        this.loading = false;
                        console.log('data:', data.logo[0]);
                        this.team_blue_logo = data.logo[0].thumbnails.tiny.url
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                
            }
        }
        
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    button {
        height: 20px;
        margin: 0;
        padding: 5px 20px;
        border-radius: 10px;
        border: 1px solid var(--color-grey);
        background-color: transparent;
        color: var(--color-grey);
        text-transform: uppercase;
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: background-color 250ms ease;
    }
    button:hover {
        background-color: black;
        border: 1px solid black;
    }
</style>