<template>
    <div class="tournament">
        <div class="inner">
            <h1>Tournament: {{ name }}</h1>
            <p><span>Status: </span>
                <span v-if="status == 'upcoming'" v-bind:class="status">Upcoming</span>
                <span v-else-if="status == 'inprogress'" v-bind:class="status">In Progress</span>
                <span v-else-if="status == 'finished'" v-bind:class="status">Finished</span>
                <span v-else></span>
            </p>
            <p><small><router-link :to="{ name: 'Tournaments', params: {}}">&larr; Tournaments overview</router-link></small></p>
            <div class="standings" v-if="standings">
                <h2>Standings</h2>
                <div v-if="loading" class="loading">Loading...</div>
                <div v-if="error" class="error">{{ error }}</div>
                <div v-if="standings" class="table">
                    <table>
                        <thead>
                            <tr>
                                <th><small>Name</small></th>
                                <th><small>Games</small></th>
                                <th><small>Wins</small></th>
                                <th><small>Losses</small></th>
                                <th><small>Goals</small></th>
                                <th><small>Points</small></th>
                            </tr>
                        </thead>
                        <tbody>
                            <StandingRow v-for="(item, index) in standings_results" v-bind:key="index" :item="item" />
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="games">
                <h2>Games</h2>
                <div v-if="loading" class="loading">Loading...</div>
                <div v-if="error" class="error">{{ error }}</div>
                <div v-if="games" class="table">
                    <table>
                        <thead>
                            <tr>
                                <!--<th><small>ID</small></th>-->
                                <th><small>Status</small></th>
                                <th><small>Date Start</small></th>
                                <th><small>Team Red</small></th>
                                <th><small>Team Blue</small></th>
                                <th><small>Score</small></th>
                            </tr>
                        </thead>
                        <tbody>
                            <GameRow v-for="(item, index) in games" v-bind:key="index" :item="item" />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import StandingRow from '@/components/StandingRow.vue'
    import GameRow from '@/components/GameRow.vue'
    
    export default {
        
        components: {
            StandingRow,
            GameRow
        },
        
        data() {
            return {                
                loading: true,
                name: null,
                status: null,
                standings: null,
                standings_results: null,
                games: null,
                baseUrl: 'https://api.baserow.io'
            }
        },
        
        // props: ["tid"],
        props: {
            tid: String
        },
        
        created() {
            this.fetchTournament()
            this.fetchStandings()
            this.fetchGames()
        },
        
        // Lifecycle hooks are called at different stages
        // of a component's lifecycle.
        // This function will be called when the component is mounted.
        mounted() {},
        
        methods: {
            convertDate(date){
                if( date === null ){
                    return '';  
                } else {
                    const dateObject = new Date(date),
                          day = String(dateObject.getDate()).padStart(2, '0'),
                          month = String((dateObject.getMonth() + 1)).padStart(2, '0'),
                          year = dateObject.getFullYear(),
                          hour = String(dateObject.getHours()).padStart(2, '0'),
                          minutes = String(dateObject.getMinutes()).padStart(2, '0');
                    return `${day}/${month}/${year} - ${hour}:${minutes}`;                    
                }                
            },
            getTeamName(array_of_objects){
                //console.log(object[0]);
                const [first] = array_of_objects;
                return first.value
            },
            countGames(games){
                return games.length
            },
            fetchTournament() {
                //console.log(this.tid);
                let url = new URL('/api/database/rows/table/162680/' + this.tid +'/?user_field_names=true', this.baseUrl);
                //console.log('url.href', url.href);

                // Request() constructor
                const headers = new Headers({
                    Authorization: "Token ElvFpuln7uKKxkC7C34WnKY4Z5RdA1ND"
                });
                const request = new Request(url.href, {
                    method: "GET",
                    headers: headers,
                    mode: "cors",
                    cache: "default"
                });
                fetch(request)
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error("Something went wrong on API server!");
                        }
                    })
                    .then((data) => {
                        //console.log('fetchTournament', data);
                        this.loading = false;
                        this.name = data.name
                        this.status = data.status
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                
            },
            fetchStandings() {
                this.error = null
                this.loading = true 
                //console.log(this.tid);
                let url = new URL('/api/database/rows/table/162681/?user_field_names=true&order_by=date_start&filter__field_1077844__equal=finished&filter__field_1073643__link_row_has=' + this.tid, this.baseUrl);
                //console.log('url.href', url.href);

                // Request() constructor
                const headers = new Headers({
                    Authorization: "Token ElvFpuln7uKKxkC7C34WnKY4Z5RdA1ND"
                });
                const request = new Request(url.href, {
                    method: "GET",
                    headers: headers,
                    mode: "cors",
                    cache: "default"
                });
                fetch(request)
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error("Something went wrong on API server!");
                        }
                    })
                    .then((data) => {
                        //console.log('fetchGames', data.results);
                        this.loading = false;
                    let teams = [];
                    for (const game of data.results) {
                        let team_red_object = {
                            name: game.team_red[0].value,
                            games: 1,
                            goals: Number(game.team_red_goals),
                            wins: (game.team_red[0].id == game.winner[0].id) ? 1 : 0,
                            losses: (game.team_red[0].id != game.winner[0].id) ? 1 : 0,
                        }
                        let team_blue_object = {
                            name: game.team_blue[0].value,
                            games: 1,
                            goals: Number(game.team_blue_goals),
                            wins: (game.team_blue[0].id == game.winner[0].id) ? 1 : 0,
                            losses: (game.team_blue[0].id != game.winner[0].id) ? 1 : 0,
                        }
                        teams.push(team_red_object);
                        teams.push(team_blue_object);
                    }
                    console.log('teams', teams)
                    
                    /*let recipe = [  {    Type:"AAAA",    Total: 20  },  {    Type:"BBBB",    Total: 20,  },  {    Type:"BBBB",    Total: 20,  },  {    Type:"AAAA",    Total: 20,  },  {    Type:"AAAA",    Total: 20,  }]*/
                    let result = Object.values(teams.reduce(
                        (r, {name, games, wins, losses, goals}) => {
                            (r[name] || (r[name] = [name, 0, 0, 0, 0, 0]))[1] += games;
                            (r[name] || (r[name] = [name, 0, 0, 0, 0, 0]))[2] += wins;
                            (r[name] || (r[name] = [name, 0, 0, 0, 0, 0]))[3] += losses;
                            (r[name] || (r[name] = [name, 0, 0, 0, 0, 0]))[4] += goals;
                            (r[name] || (r[name] = [name, 0, 0, 0, 0, 0]))[5] += (wins * 3);
                            return r;
                        }, {}
                    ));
                    result = result.sort((a, b) => b[4] - a[4] || a[5] - b[5])
                    if(result.length > 0){
                        this.standings = true;
                    }
                    this.standings_results = result
                    
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                
            },
            fetchGames() {
                this.error = null
                this.loading = true 
                //console.log(this.tid);
                let url = new URL('/api/database/rows/table/162681/?user_field_names=true&order_by=date_start&filter__field_1073643__link_row_has=' + this.tid, this.baseUrl);
                //console.log('url.href', url.href);

                // Request() constructor
                const headers = new Headers({
                    Authorization: "Token ElvFpuln7uKKxkC7C34WnKY4Z5RdA1ND"
                });
                const request = new Request(url.href, {
                    method: "GET",
                    headers: headers,
                    mode: "cors",
                    cache: "default"
                });
                fetch(request)
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error("Something went wrong on API server!");
                        }
                    })
                    .then((data) => {
                        //console.log('fetchGames', data.results);
                        this.loading = false;
                        this.games = data.results
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                
            },
            checkWinner(winner_array, team_array){
                if( winner_array.length && winner_array[0].id == team_array[0].id ){
                    return '🏆'
                }
            }
        }
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .standings {
        margin-top: 40px;
    }
    .games {
        margin-top: 40px;
    }
</style>