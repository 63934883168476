<template>
    <div class="posts">
        <div v-if="loading" class="loading">Loading...</div>
        <div v-if="error" class="error">{{ error }}</div>
        <div v-if="items" class="content">
            <div v-for="(item, index) in items" v-bind:key="index">
                <router-link :to="{ name: 'Post', params: { pid: item.id }}"><p>{{ item.title }}</p></router-link>
            </div>
        </div>
    </div>
</template>

<script>    
    export default {        
        data() {
            return {
                loading: false,
                error: null,
                items: null,
                baseUrl: 'https://jsonplaceholder.typicode.com/posts/'
            }
        },
        
        created() {
            this.fetchData()
        },
        
        methods: {
            fetchData() {
                this.error = this.post = null
                this.loading = true
                let url = new URL(this.baseUrl);
                console.log(url.href);
                fetch(url.href)
                    .then(response => response.json())
                    .then(data => {
                    this.loading = false;
                    //console.log(data);
                    this.items = data
                    console.log('this.items', this.items);
                })
                
            }
        }
        
    };
</script>