<template>
     <tr>
        <td>{{ item[0] }}</td>
        <td>{{ item[1] }}</td>
        <td>{{ item[2] }}</td>
        <td>{{ item[3] }}</td>
        <td>{{ item[4] }}</td>
        <td>{{ item[5] }}</td>
        
    </tr>
</template>

<script>
    
    export default {
        name: 'StandingRow',
        
        // Properties returned from data() become reactive state
        // and will be exposed on `this`.
        data() {
            return {
            }
        },       
        
        props: {
            item: Object
        },
        
        created() {
            //console.log(this.item);
        },
        
        // Lifecycle hooks are called at different stages
        // of a component's lifecycle.
        // This function will be called when the component is mounted.
        mounted() {},
        
        // Methods are functions that mutate state and trigger updates.
        // They can be bound as event listeners in templates.
        methods: {
        }
        
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>