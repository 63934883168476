<template>
    <!--<img alt="Vue logo" src="./assets/logo/logo.png">-->
    <Header tagline="Foosball App" />
    <main>
        <router-view v-slot="{ Component, route }">
            <Transition name="fade" :duration="{ enter: 500, leave: 500 }"  mode="out-in">
                <component :is="Component" :key="route.path" />
            </Transition>
        </router-view>
    </main>
    <Footer />
</template>

<script>
    import Header from './components/Header.vue'
    import Footer from './components/Footer.vue'

    export default {
        name: 'App',        
        components: {
            Header,
            Footer
        },
        mounted() {
            document.title = 'Signifly - Foosball'
        }
    }

</script>

<style>
    :root {
        --color-grey: rgb(188, 188, 188);
    }
    
    
    @font-face {
        font-family: 'Aeonik';
        src: url('./assets/webfonts/Aeonik-Regular.woff2') format('woff2'),
            url('./assets/webfonts/Aeonik-Regular.woff') format('woff');
        font-weight: 400;
        font-style: normal;
    }
    html {
        box-sizing: border-box;
    }
    *, *:before, *:after {
        box-sizing: inherit;
    }
    body {
        margin: 0;
        font-family: 'Aeonik', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: clamp(12px, 2vw, 14px);
    }
    h1, h2, h3, h4, h5 {
        font-weight: 400;
        margin-top: 0;
    }
    p:first-child {
        margin-top: 0;
    }
    
    /* main */
    main {
        margin: 40px 0;
        padding: 0 20px;
    }
    
    /* we will explain what these classes do next! */
    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.5s ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
    
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
    
    /* inner */
    .inner {
        position: relative;
        margin: 0 auto;
        max-width: 960px;
    }
    
    /* table */
    table {
        width: 100%;
        margin: 0;
        padding: 0;
        border-collapse: separate;
    }
    table tr {
        position: relative;
        transition: opacity 250ms ease;
    }
    table.withlinks tbody tr:hover {
        cursor: pointer;
        user-select: none;
        opacity: 0.25;
    }
    table tr:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: var(--color-grey);
        display: block;
    }
    table tr th:not(:last-child),
    table tr td:not(:last-child) {
        text-align: left;
    }
    table tr th:last-child,
    table tr td:last-child {
        text-align: right;
    }
    table tr th {
        font-weight: 400;
        padding: 10px 0;
        text-transform: uppercase;
        color: var(--color-grey);
    } 
    table tr td {
        padding: 10px 0;
    }
    
    /* logo */
    table tr td .logo {
        vertical-align: middle;
        margin-right: 5px;
        display: inline-block;
    }
    table tr td .winner {
        vertical-align: middle;
        margin-left: 5px;
        display: inline-block;
        animation: ring 4s .7s ease-in-out infinite;
        transform-origin: 50% 50%;
    }
    table tr td .logo img {
        max-width: 24px;
        height: auto;
        aspect-ratio: 1 / 1;
        object-fit: contain;
    }
    
    /* small link */
    small a {
        font-weight: 400;
        text-transform: uppercase;
        color: var(--color-grey);
    }
    
    ._win_probability {
        color: var(--color-grey);
    }
    
    /* blink effect */
    @keyframes blink {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    
    @keyframes ring {
        0% { transform: rotate(0); }
        1% { transform: rotate(30deg); }
        3% { transform: rotate(-28deg); }
        5% { transform: rotate(34deg); }
        7% { transform: rotate(-32deg); }
        9% { transform: rotate(30deg); }
        11% { transform: rotate(-28deg); }
        13% { transform: rotate(26deg); }
        15% { transform: rotate(-24deg); }
        17% { transform: rotate(22deg); }
        19% { transform: rotate(-20deg); }
        21% { transform: rotate(18deg); }
        23% { transform: rotate(-16deg); }
        25% { transform: rotate(14deg); }
        27% { transform: rotate(-12deg); }
        29% { transform: rotate(10deg); }
        31% { transform: rotate(-8deg); }
        33% { transform: rotate(6deg); }
        35% { transform: rotate(-4deg); }
        37% { transform: rotate(2deg); }
        39% { transform: rotate(-1deg); }
        41% { transform: rotate(1deg); }
        43% { transform: rotate(0); }
        100% { transform: rotate(0); }
    }
    
    .inprogress {
        animation: 2000ms linear infinite blink;
    }
</style>