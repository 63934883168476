<template>
    <div class="tournament">
        <div class="inner">
            <h1>Tournament: {{ name }}</h1>
            <p><small><router-link :to="{ name: 'Tournaments', params: {}}">&larr; Tournaments overview</router-link></small></p>
            <div class="standings">
                <h2>Standings</h2>
                <div v-if="standings" class="table">
                    <table>
                        <thead>
                            <tr>
                                <th><small>ID</small></th>
                                <th><small>Status</small></th>
                                <th><small>Date Kick-off</small></th>
                                <th><small>Team Red</small></th>
                                <th><small>Team Blue</small></th>
                                <th><small>Score</small></th>
                            </tr>
                        </thead>
                        <tbody>
                            <StandingRow v-for="(item, index) in standings" v-bind:key="index" :item="item" />
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="games">
                <h2>Games</h2>
                <div v-if="games" class="table">
                    <table>
                        <thead>
                            <tr>
                                <th><small>ID</small></th>
                                <th><small>Status</small></th>
                                <th><small>Date Kick-off</small></th>
                                <th><small>Team Red</small></th>
                                <th><small>Team Blue</small></th>
                                <th><small>Score</small></th>
                            </tr>
                        </thead>
                        <tbody>
                            <GameRow v-for="(item, index) in games" v-bind:key="index" :item="item" />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import StandingRow from '@/components/StandingRow.vue'
    import GameRow from '@/components/GameRow.vue'
    
    export default {
        
        components: {
            StandingRow,
            GameRow
        },
        
        data() {
            return {                
                loading: true,
                name: null,
                standings: null,
                games: null,
                baseUrl: 'https://api.baserow.io'
            }
        },
        
        // props: ["tid"],
        props: {
            tid: String
        },
        
        created() {
            this.fetchTournament()
            this.fetchGames()
        },
        
        methods: {
            convertDate(date){
                if( date === null ){
                    return '';  
                } else {
                    const dateObject = new Date(date),
                          day = String(dateObject.getDate()).padStart(2, '0'),
                          month = String((dateObject.getMonth() + 1)).padStart(2, '0'),
                          year = dateObject.getFullYear(),
                          hour = String(dateObject.getHours()).padStart(2, '0'),
                          minutes = String(dateObject.getMinutes()).padStart(2, '0');
                    return `${day}/${month}/${year} - ${hour}:${minutes}`;                    
                }                
            },
            getTeamName(array_of_objects){
                //console.log(object[0]);
                const [first] = array_of_objects;
                return first.value
            },
            countGames(games){
                return games.length
            },
            fetchTournament() {
                //console.log(this.tid);
                let url = new URL('/api/database/rows/table/162680/' + this.tid +'/?user_field_names=true', this.baseUrl);
                //console.log('url.href', url.href);

                // Request() constructor
                const headers = new Headers({
                    Authorization: "Token ElvFpuln7uKKxkC7C34WnKY4Z5RdA1ND"
                });
                const request = new Request(url.href, {
                    method: "GET",
                    headers: headers,
                    mode: "cors",
                    cache: "default"
                });
                fetch(request)
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error("Something went wrong on API server!");
                        }
                    })
                    .then((data) => {
                        //console.log('fetchTournament', data);
                        this.loading = false;
                        this.name = data.name
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                
            },
            fetchGames() {
                this.error = null
                this.loading = true 
                //console.log(this.tid);
                let url = new URL('/api/database/rows/table/162681/?user_field_names=true&filter__field_1073643__link_row_has=' + this.tid, this.baseUrl);
                //console.log('url.href', url.href);

                // Request() constructor
                const headers = new Headers({
                    Authorization: "Token ElvFpuln7uKKxkC7C34WnKY4Z5RdA1ND"
                });
                const request = new Request(url.href, {
                    method: "GET",
                    headers: headers,
                    mode: "cors",
                    cache: "default"
                });
                fetch(request)
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error("Something went wrong on API server!");
                        }
                    })
                    .then((data) => {
                        //console.log('fetchGames', data.results);
                        this.loading = false;
                        this.standings = data.results
                        this.games = data.results
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                
            },
            checkWinner(winner_array, team_array){
                if( winner_array.length && winner_array[0].id == team_array[0].id ){
                    return '🏆'
                }
            }
        }
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .standings {
        margin-top: 40px;
    }
    .games {
        margin-top: 40px;
    }
</style>