<template>
    <footer>
        <div class="copyright"><small>&copy; {{ date }} Signifly</small> <!--<button @click="incrementCount">Count is: {{ count }}</button>--></div>
    </footer>
</template>

<script>
    export default {
        name: 'PageFooter',
        
        // Properties returned from data() become reactive state
        // and will be exposed on `this`.
        data() {
            return {
                date: 0,
                count: 0
            }
        },
        
        // Methods are functions that mutate state and trigger updates.
        // They can be bound as event listeners in templates.
        methods: {
            incrementCount() {
                this.count++
            },
            setDate(){
                const today = new Date();
                this.date = today.getFullYear();
            }
        },
        
        // Lifecycle hooks are called at different stages
        // of a component's lifecycle.
        // This function will be called when the component is mounted.
        mounted() {
            this.setDate()
        }
        
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
    }
</style>