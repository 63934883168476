<template>
    <div class="home">
        <div class="inner">
            <h1>Live Games</h1>
            <div class="games">
                <div v-if="loading" class="loading">Loading...</div>
                <div v-if="error" class="error">{{ error }}</div>
                <div v-if="games" class="table">
                    <table>
                        <thead>
                            <tr>
                                <!--<th><small>ID</small></th>-->
                                <th><small>Status</small></th>
                                <th><small>Date Started</small></th>
                                <th><small>Team Red</small></th>
                                <th><small>Team Blue</small></th>
                                <th><small>Score</small></th>
                            </tr>
                        </thead>
                        <tbody>
                            <GameRow v-for="(item, index) in games" v-bind:key="index" :item="item" />
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="inner">
                <div class="splide">
                    <Splide :options="{ rewind: true, arrows: false, pagination: false, perPage: 1, autoplay: true, type: 'fade', speed: 4000, interval: 4000, pauseOnHover: false }" aria-label="Signifly">
                        <SplideSlide>
                            <img src="../assets/media/signifly-01.webp" alt="Signifly">
                        </SplideSlide>
                        <SplideSlide>
                            <img src="../assets/media/signifly-02.webp" alt="Signifly">
                        </SplideSlide>
                        <SplideSlide>
                            <img src="../assets/media/signifly-03.webp" alt="Signifly">
                        </SplideSlide>
                    </Splide>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GameRow from '@/components/GameRow.vue'
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import '@splidejs/vue-splide/css';

    export default {
        
        components: {
            Splide,
            SplideSlide,
            GameRow
        },
        
        data() {
            return {
                loading: true,
                games: null,
                baseUrl: 'https://api.baserow.io'
            }
        },
        
        created() {
            this.fetchGames()
        },
        
        methods: {
            fetchGames() {
                this.error = null
                this.loading = true 
                let url = new URL('/api/database/rows/table/162681/?user_field_names=true&order_by=date_start&filter__field_1077844__equal=inprogress', this.baseUrl);
                //console.log('url.href', url.href);

                // Request() constructor
                const headers = new Headers({
                    Authorization: "Token ElvFpuln7uKKxkC7C34WnKY4Z5RdA1ND"
                });
                const request = new Request(url.href, {
                    method: "GET",
                    headers: headers,
                    mode: "cors",
                    cache: "default"
                });
                fetch(request)
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error("Something went wrong on API server!");
                        }
                    })
                    .then((data) => {
                        //console.log('fetchGames', data.results);
                        this.loading = false;
                        this.games = data.results
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                
            }
        }
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .games {
        margin-bottom: 40px;
    }
    .splide img {
        aspect-ratio: 1920/1080;
        object-fit: cover;
        width: 100%;
        height: auto;
        display: block;
    }
</style>