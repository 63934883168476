<template>
    <tr v-on:click="goToTournament">
        <td>{{ item.name }} <!--<small><router-link :to="{ name: 'Tournament', params: { tid: item.id }}">View &rarr;</router-link></small>--></td>
        <td>
            <span v-if="item.status == 'upcoming'" v-bind:class="item.status">Upcoming</span>
            <span v-else-if="item.status == 'inprogress'" v-bind:class="item.status">In Progress</span>
            <span v-else-if="item.status == 'finished'" v-bind:class="item.status">Finished</span>
            <span v-else></span>
        </td>
        <td v-html="countGames(item.games)"></td>
        <td>&rarr;</td>
    </tr>
</template>

<script>
    import router from '@/router'
    
    export default {
        name: 'TournamentRow',
        
        // Properties returned from data() become reactive state
        // and will be exposed on `this`.
        data() {
            return {}
        },       
        
        props: {
            item: Object
        },
        
        created() {
            //console.log(this.item);
        },
        
        // Lifecycle hooks are called at different stages
        // of a component's lifecycle.
        // This function will be called when the component is mounted.
        mounted() {},
        
        // Methods are functions that mutate state and trigger updates.
        // They can be bound as event listeners in templates.
        methods: {
            convertDate(date){
                if( date === null ){
                    return '';  
                } else {
                    const dateObject = new Date(date),
                          day = String(dateObject.getDate()).padStart(2, '0'),
                          month = String((dateObject.getMonth() + 1)).padStart(2, '0'),
                          year = dateObject.getFullYear(),
                          hour = String(dateObject.getHours()).padStart(2, '0'),
                          minutes = String(dateObject.getMinutes()).padStart(2, '0');
                    return `${day}/${month}/${year} - ${hour}:${minutes}`;                    
                }                
            },
            countGames(games){
                return games.length
            },
            goToTournament(){
                router.push({ name: 'Tournament', params: { tid: this.item.id } })
            }
        }
        
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>