<template>
    <div class="admin">
        <div class="inner">
            <h1>Admin</h1>
            <div v-if="authenticated == false" class="form">
                <form @submit.prevent="checkForm">
                    <div class="field"><input type="email" name="username" placeholder="Username"></div>
                    <div class="field"><input type="password" name="password" placeholder="Password"></div>
                    <div class="button"><button type="submit"><small>Login</small></button></div>
                </form>
            </div>
            <div v-if="authenticated == true" class="games">
                <h2>Games in progress</h2>
                <div v-if="loading" class="loading">Loading...</div>
                <div v-if="error" class="error">{{ error }}</div>
                <div v-if="games" class="table">
                    <table>
                        <thead>
                            <tr>
                                <!--<th><small>ID</small></th>-->
                                <th><small>Status</small></th>
                                <th><small>Date Start</small></th>
                                <th><small>Team Red</small></th>
                                <th><small>Team Blue</small></th>
                                <th><small>Score</small></th>
                            </tr>
                        </thead>
                        <tbody>
                            <GameInprogressRow v-for="(item, index) in games" v-bind:key="index" :item="item" />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GameInprogressRow from '@/components/GameInprogressRow.vue'

    export default {
        
        components: {
            GameInprogressRow
        },
        
        data() {
            return {
                authenticated: false,          
                loading: true,
                games: null,
                baseUrl: 'https://api.baserow.io'
            }
        },
        
        created() {
            this.fetchGames()
        },
        
        methods: {
            checkForm(){
                this.authenticated = true
            },
            fetchGames() {
                this.error = null
                this.loading = true 
                let url = new URL('/api/database/rows/table/162681/?user_field_names=true&order_by=date_start&filter__field_1077844__not_equal=finished', this.baseUrl);
                //console.log('url.href', url.href);

                // Request() constructor
                const headers = new Headers({
                    Authorization: "Token ElvFpuln7uKKxkC7C34WnKY4Z5RdA1ND"
                });
                const request = new Request(url.href, {
                    method: "GET",
                    headers: headers,
                    mode: "cors",
                    cache: "default"
                });
                fetch(request)
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error("Something went wrong on API server!");
                        }
                    })
                    .then((data) => {
                        //console.log('fetchGames', data.results);
                        this.loading = false;
                        this.games = data.results
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                
            }
        }
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    form {
        display: flex;
        flex-direction: column;
        grid-row-gap: 10px;
    }
    input {
        margin: 0;
        padding: 5px 0;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid var(--color-grey);
        outline: none;
    }
    button {
        height: 20px;
        margin: 0;
        padding: 5px 20px;
        border-radius: 10px;
        border: 1px solid var(--color-grey);
        background-color: transparent;
        color: var(--color-grey);
        text-transform: uppercase;
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: background-color 250ms ease;
    }
    button:hover {
        background-color: black;
        border: 1px solid black;
    }
</style>