<template>
    <tr>
        <td>{{ item.name }}</td>
        <td><img v-bind:src="item.logo[0].thumbnails.tiny.url"></td>
        <td>
            <ul class="players">
                <li v-for="(player, index) in item.players_full_names" v-bind:key="index">{{ player.value }}</li>
            </ul>
        </td>
        <td><span v-html="item.games_total"></span></td>
        <td><span v-html="item.goals_total"></span></td>
        <td><span v-html="item.games_won_total"></span></td>
    </tr>
</template>

<script>
    
    export default {
        name: 'TeamRow',
        
        // Properties returned from data() become reactive state
        // and will be exposed on `this`.
        data() {
            return {
                loading: false,
                error: null,
                baseUrl: 'https://api.baserow.io',
            }
        },       
        
        props: {
            item: Object
        },
        
        created() {
            //console.log(this.item);
        },
        
        // Lifecycle hooks are called at different stages
        // of a component's lifecycle.
        // This function will be called when the component is mounted.
        mounted() {},
        
        // Methods are functions that mutate state and trigger updates.
        // They can be bound as event listeners in templates.
        methods: {            
            countGoals(goals){
                return goals.length
            },            
            countGames(games){
                return games.length
            },            
            countWins(wins){
                return wins.length
            }
        }
        
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    ul.players {
        margin: 0;
        padding: 0;
        list-style: none;
    }
</style>