<template>
    <div class="posts">
        <div class="inner">
            <h1>Tournaments</h1>
            <div v-if="loading" class="loading">Loading...</div>
            <div v-if="error" class="error">{{ error }}</div>
            <div v-if="tournaments" class="table">
                <table class="withlinks">
                    <thead>
                        <tr>
                            <th><small>Name</small></th>
                            <th><small>Status</small></th>
                            <th><small>Games</small></th>
                            <th><small>Info</small></th>
                        </tr>
                    </thead>
                    <tbody>
                        <TournamentRow v-for="(item, index) in tournaments" v-bind:key="index" :item="item" />
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import TournamentRow from '@/components/TournamentRow.vue'
    
    export default {
        
        components: {
            TournamentRow
        },
        
        data() {
            return {
                loading: false,
                error: null,
                tournaments: null,
                baseUrl: 'https://api.baserow.io'
            }
        },
        
        created() {
            this.fetchTournaments()
        },
        
        methods: {
            fetchTournaments() {
                this.error = null
                this.loading = true 
                
                // baserow.io Tournaments table fields
                // field_1073613 date_start
                
                let url = new URL('/api/database/rows/table/162680/?user_field_names=true&order_by=date_start&page=1&size=100', this.baseUrl);
                //console.log('url.href', url.href);

                // Request() constructor
                const headers = new Headers({
                    Authorization: "Token ElvFpuln7uKKxkC7C34WnKY4Z5RdA1ND"
                });
                const request = new Request(url.href, {
                    method: "GET",
                    headers: headers,
                    mode: "cors",
                    cache: "default"
                });
                fetch(request)
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            throw new Error("Something went wrong on API server!");
                        }
                    })
                    .then((data) => {
                        console.log(data.results);
                        this.loading = false;
                        this.tournaments = data.results
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                
            }
        }
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>